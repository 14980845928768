<template>
  <page :styleWrap='{background: "#f6f6f6"}' ref='wrap'>
    <div>
     <header-bar v-if="!isMobile">
        <div slot='leading' @click="$router.back()">
          <i  style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      
        <div class="resContent" ref='content' v-show='result.length'>
        
            <div
            v-for='(item, index) in result'
            
            :key='index'>
            <div class="resItem underlineMargin" >
                <div class="f_flex f_a_c">
                    <div class="avatar">
                        <img style="width: 100%; height: 100%; border-radius: 44px" :src="item.avatar" alt="">
                    </div>
                    <div class="f_g_1" style="width: 100%;">
                        <div  class="f_flex f_j_sb">
                            <div class="title">{{item.name}}</div>
                            <div class="subTitle">{{item.createTime}}</div>
                        </div>
                        <div class="subTitle">{{item.phone}}</div>
                    </div>
                </div>
                
                <div class="f_flex f_j_e" v-if="item.auditingStatus == 3">
                    <button class="button-primary" @click='selectUser(item)'>通过</button>
                    <button class="button-plain" @click='userReject(item.id)'>拒绝</button>
                </div>
                
            </div>
            </div>

            <div class="f_flex f_j_c subTitle" v-if="isEnd">
              已经到底
            </div>
        </div>



        <van-action-sheet v-model="showUserDetail" title="分配人员">
            <div class="content">

            <van-cell-group>

                <van-cell >
               
                <template #title>
                    <span class="custom-title">头像</span>
                    
                </template>
                <template #right-icon>
                    <div class="avatar">
                        <img style="width: 100%; height: 100%; border-radius: 44px" :src="user.avatar" alt="">
                    </div>
                </template>
             </van-cell>
                <van-cell title="姓名" :value="user.name" />
                <van-cell title="手机号" :value="user.phone" />
                <van-cell title="部门" is-link :value="selectDeptDesc ? selectDeptDesc : '请选择'" @click="showPickerDept = true"/>
                <van-cell title="角色" is-link :value="selectRoleDesc ? selectRoleDesc : '请选择'" @click="showPickerRole = true"/>
            </van-cell-group>
            <div class="footer f_flex f_j_c f_a_c">
                <button style="width: 110px;" @click='userPass' class="btn btn_radius theme_bg">确定</button>
            </div>
            </div>
        </van-action-sheet>

        <van-popup v-model="showPickerDept" round position="bottom">
            <van-picker
                show-toolbar
                :columns="deptTitles"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>


       <van-popup v-model="showPickerRole" round position="bottom">

            <div class="content">
                <div class="f_flex f_j_e f_a_c">
                    <button class="van-picker__confirm" @click='confirmRole'>确定</button>
                </div>

                <checkbox-group v-model="choseRoleList" ref='checkboxGroup'>
                <van-cell v-for='(ii, k) in roleList' :key='k'>
                    <template #title>
                    <checkbox :name="ii.id">
                        <div class="f_flex f_a_c">
                        <!-- <div class="avatar f_flex f_a_c f_j_c theme_bg">
                            <img v-if='ii.avatar' style="width: 100%; height: 100%" :src="ii.avatar" alt="">
                            
                        </div> -->
                        <div>
                            <div class="name">{{ii.name}}</div>
                            
                        </div>
                        </div>
                    </checkbox>
                    </template>
                </van-cell>
            </checkbox-group>
            </div>
       </van-popup>

    </div>
  </page>
</template>

<script>

import userApi from '@/api/user'
import {mapActions} from 'vuex'
import scrollMixins from '@/common/mixins/scroll'
import {  CheckboxGroup, Checkbox } from 'vant';

export default {
  components: {
    CheckboxGroup, Checkbox
  },
  mixins: [scrollMixins],
  data: ()=> ({
    result: [],
  
    pageNo: 1,
    pageSize: 20,
    isEnd: false,
    isMobile: false,
    showUserDetail: false,
    user: {},

    showPickerDept: false,
    showPickerRole: false,
    depts: [],
    deptTitles: [],
    selectDeptId: '',
    roleList: [],
    choseRoleList: [],
    selectDeptDesc: '',
    selectRoleDesc: ''
  }),
  mounted() {
    console.log("人员管理页面 mounted")
    const {JSESSIONID} = this.$route.query

    console.log(JSESSIONID)
    this.isMobile = JSESSIONID
    if (JSESSIONID) {
         this.$global.setItem({
            JSESSIONID: JSESSIONID,
            
            })
    }
  
    const wrap = this.$refs.wrap.$refs.pageContent_contain
    const content = this.$refs.content
    this.getList()
    this.getDepts()
    this.getRoles()
    this.onContentScroll(wrap, content, this.isEnd, ()=> {
      this.getList()
    })
  },
  watch: {
   
  },
  methods: {
    ...mapActions([
      'set_styleNum'
    ]),
    getList() {
      const {companyId} = this.$route.query
      
      if (!this.isEnd) {
        userApi.companyUserList({
          companyId: companyId,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        }).then(res => {
          if (res.data && res.data.list) {
            const list = res.data.list
            
          
            this.result.push(...list)
          
            
          }
          if (!res.data.endFlag) {
            this.pageNo++
          } else {
            this.isEnd = true
          }
        })
      }
    },

    getDepts() {
        const {companyId} = this.$route.query

        userApi.companyDepts(
            {
                companyId: companyId, 
            }
        ).then(res => {
            if (res.data && res.data.list) {
                this.depts = res.data.list
                this.deptTitles.push(...this.depts.map(x => x.name))
            }
          
        })
    },

    getRoles() {
        const {companyId} = this.$route.query

        userApi.companyRoles(
            {
                companyId: companyId, 
            }
        ).then(res => {
            if (res.data && res.data.list) {
                this.roleList = res.data.list
            }
          
        })
    },

    selectUser(user) {
        this.user = user
        this.showUserDetail = true
        this.selectDeptId = ''
        this.selectDeptDesc = ''
        this.choseRoleList = []
        this.selectRoleDesc = ''
    },
    
    userPass() {

        if (!this.selectDeptId) {
          this.$toast('请选择分配一个部门')
          return
        }

        const {companyId} = this.$route.query
        userApi.companyUserPass({
          companyId: companyId,
          departmentId: this.selectDeptId,
          roleIds:this.choseRoleList,
          id: this.user.id,
          
        }).then(res => {
          if (res.code == 0) {
              this.$toast('审核成功')
              this.showUserDetail = false
              this.refreshPage()    
          }
          
        })
    },

     userReject(userId) {
        
        
        this.$dialog.confirm ({
                message: '确定要拒绝这个用户申请吗？'
            }).then (() => {
                const {companyId} = this.$route.query
                userApi.companyUserReject({
                  companyId: companyId,
                  id: userId,
                  
                }).then(res => {
                    
                  if (res.code == 0) {
                    this.refreshPage()    
                    this.$toast('拒绝用户')
                      
                  }
                  
                })
            }).catch (() => {
        })
        
    },

    onConfirm(value) {
      this.selectDeptDesc = value;
      this.showPickerDept = false;
      console.log( this.depts)
      for (let d of this.depts) {
        
          if (d.name == this.selectDeptDesc) {
              this.selectDeptId = d.id
          }
      }
    },

    confirmRole() {
        console.log("选择的角色", this.choseRoleList)
        this.showPickerRole = false

        for (let r of this.choseRoleList) {
            if (this.selectRoleDesc) {
                this.selectRoleDesc += '、'
            }
             for (let role of this.roleList) {
                if (r == role.id) {
                    this.selectRoleDesc += role.name
                }
            }
                
        }  
    },

    refreshPage() {
      const {companyId} = this.$route.query
      this.result = []
      this.pageNo = 1
      this.isEnd = false
      userApi.companyUserList({
          companyId: companyId,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        }).then(res => {
          if (res.data && res.data.list) {
            const list = res.data.list
            
          
            this.result.push(...list)
          
            
          }
        })
    }
    
  },
}
</script>

<style lang="less" scoped>
.searchPanel {
  padding-left: 10px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
}
.cancle {
  font-size: 15px;
  color: #4A4A4A;
  margin: 0 15px;
}

.resContent {
  padding: 0px;
}
.resItem {
  font-size: 16px;
  color: #2D2D2D;
  padding: 12px 20px;
  background: #fff;
  line-height: 20px;
}
.newLabel {
  font-size: 12px;
  border: 1px solid #4F92E6;
  padding: 1px 2px;
  line-height: 12px;
  border-radius: 2px;
  margin-left: 7px;
}


.labelArchor {
    z-index: 1;
    box-sizing: border-box;
    padding: 0 16px;
    color: #323233;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    background-color: transparent;

}

  
.title {
    font-size: 16px;
    color: #2D2D2D;
    margin-right: 15px;
    font-weight: 400;
    line-height: 22px;
    
}

.subTitle {
    font-size: 13px;
    line-height: 18px;
    color: #9B9B9B;
}
.avatar {
    width: 44px;
    height: 44px;
    border-radius: 40px;
    overflow: hidden;
    min-width: 44px;
   margin-right: 15px;
}
.button-primary {
    width: 70px;
    height: 32px;
    background: #4F92E6;
    border-radius: 22px;
    border: 1px solid #4A95F4;
    color: #fff;
}
.button-plain {
    width: 70px;
    height: 32px;
    border-radius: 22px;
    border: 1px solid #4A95F4;
    color: #4A95F4;
    background: #fff;    
    margin-left: 20px;
}
.content {
    padding: 16px 16px 160px;
}

.footer {
    height: 200px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding-right: 12px;
    bottom: 0;
}
</style>